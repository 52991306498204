import { AnalyticsBrowser, type SegmentEvent } from '@segment/analytics-next'
import { isSSR } from '../../utils/ssrUtils.js'
import { isPPV } from '../../domain/center.js'
export const analytics = AnalyticsBrowser.load({
  writeKey: process.env.GATSBY_SEGMENT_KEY || 'K0gzBza21a8TAcfOWRtGk2B2elXlRRzh'
})
import { SponsorTier } from '@/types/models/Center.model'

type SegmentCenterClick = {
  center: string
  breadcrumbs: string
  position?: number
  positionInElement?: number
  sponsor_tier?: SponsorTier
  template: string
  zone: string
  action?: string
}

type SegmentCenterImpression = {
  center: string
  breadcrumbs: string
  position?: number
  positionInElement?: number
  sponsor_tier?: SponsorTier
  template: string
  zone: string
}

const useSegment = () => {
  return {
    feed: {
      impression: (data: SegmentCenterImpression) => {
        analytics.track('Center Impression', data)
      },
      click: (data: SegmentCenterClick) => {
        analytics.track('Center Click', data)
      }
    },
    profilePage: {
      inquirySubmitted: (data: { slug: string; verify_insurance: boolean }) => {
        analytics.track('Treatment Inquiry', {
          ...data
        })
      },
      ppv: (data: {
        title: string
        slug: string
        br: Float32Array
        sponsor_tier: SponsorTier
      }) => {
        if (!isSSR() && isPPV(data)) {
          analytics.track('PPV', data)
        }
      }
    },
    verifyBenefits: {
      opened: (data: Partial<Record<string, unknown>>) => {
        analytics.track('Verify Benefits Form', { ...data })
      },
      submitted: (data: Partial<Record<string, unknown>>) => {
        analytics.track('Benefits form submitted', { ...data })
      }
    }
  }
}

export default useSegment
