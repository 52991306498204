const react = require(`react`)
const reactDomClient = require(`react-dom/client`)

// This plugin replaces the built-in hydrateRoot() call with the one that:
// - uses startTransition, to make hydration non-blocking (https://3perf.com/talks/react-concurrency/#suspense),
// - prevents React from receiving events during hydration, which stops it from switching
//   to the blocking mode (https://3perf.com/talks/react-concurrency/#slide-27-15).
// Hydration is the biggest INP offender we have, so this (while a hack) should help
// to improve it.
exports.replaceHydrateFunction = () => {
  return (treeToRender, domElement) => {
    // If the element has children, hydrate it; otherwise, render a new tree into it
    const useHydrate = domElement && domElement.children.length

    if (useHydrate) {
      const WrapperWithUseEffect = () => {
        react.useEffect(() => {
          // Enable React event handling when hydration is complete. `useEffect` is the only
          // way to run some code once hydration is done.
          enableReactEventHandling()

          // Add additional marks in the DevTools recording to see when hydration is happening.
          // The hydrate-start mark will be missing if we’re client-side-rendering the page.
          if (performance.getEntriesByName('hydrate-start').length > 0) {
            performance.mark('hydrate-end')
            performance.measure('hydrate', 'hydrate-start', 'hydrate-end')
          }
        }, [])

        return treeToRender
      }

      react.startTransition(() => {
        // Disable React event handling when hydration starts
        disableReactEventHandling()

        // Add additional marks in the DevTools recording to see when hydration is happening
        performance.mark('hydrate-start')

        reactDomClient.hydrateRoot(domElement, react.createElement(WrapperWithUseEffect))
      })
    } else {
      const root = reactDomClient.createRoot(domElement)
      root.render(treeToRender)
    }
  }
}

// We disable only mouse events. Keyboard events can also trigger blocking hydration, but these are rare.
const eventsToDisable = [
  'mousedown',
  'mouseup',
  'touchcancel',
  'touchend',
  'touchstart',
  'auxclick',
  'dblclick',
  'pointercancel',
  'pointerdown',
  'pointerup',
  'click',
]

const eventDisabler = (e) => {
  // We call `stopImmediatePropagation` to prevent React from receiving the event. This works
  // because we install our event listener before React installs its own. Importantly, this still
  // allows native links inside the React root to work, so the user can still navigate the site
  // even during hydration.
  e.stopImmediatePropagation()
}

function disableReactEventHandling() {
  for (const eventName of eventsToDisable) {
    // `#___gatsby` is the React root *and* also where React installs all its event listeners.
    // (When you have a `<button onClick={...} />`, React actually installs the event listener
    // on `#___gatsby`, not on the button.)
    document.querySelector('#___gatsby')
      .addEventListener(eventName, eventDisabler, { capture: true })
  }
}

function enableReactEventHandling() {
  for (const eventName of eventsToDisable) {
    document.querySelector('#___gatsby')
      .removeEventListener(eventName, eventDisabler, { capture: true })
  }
}